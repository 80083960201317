import classnames from 'classnames';
import s from './ContentArea.scss';
import {getProductWidgetSettingsFromProps} from '../../commons/styleParamsService';
import {WidgetAlignmentEnum, WidgetAreaEnum} from '../../commons/enums';
import {ActionButton} from '../ActionButton/ActionButton';
import * as React from 'react';
import {IProvidedGlobalProps, withGlobalProps} from '../../providers/globalPropsProvider';
import {withTranslations} from '@wix/wixstores-client-common-components/dist/es/src/outOfIframes/translations';
import {IProvidedTranslationProps} from '@wix/wixstores-client-common-components/dist/src/outOfIframes/translations';
import {ProductDetails} from '../ProductDetails/ProductDetails';

export enum DataHook {
  Root = 'product-widget-content-area',
  Content = 'product-widget-content',
}

export interface IContentAreaProps extends IProvidedGlobalProps, IProvidedTranslationProps {}

class ContentAreaComp extends React.Component<IContentAreaProps> {
  public render(): React.ReactNode {
    const {
      globals: {isMobile, horizontalLayout},
    } = this.props;
    const settings = getProductWidgetSettingsFromProps(this.props);
    const contentAreaClasses = classnames(s.contentArea, {
      [s.horizontal]: horizontalLayout,
      [s.vertical]: !horizontalLayout,
      [s.borderAreaContent]: settings.hoverBorderArea === WidgetAreaEnum.CONTENT,
    });
    const contentClasses = classnames(s.content, this.getAlignmentClass(settings.alignment), {
      [s.horizontal]: horizontalLayout,
      [s.vertical]: !horizontalLayout,
    });
    const isDesktopAndHoverToggled = !isMobile && settings.hoverButtonToggle;
    const shouldShowActionButton = settings.widgetButtonToggle || isDesktopAndHoverToggled;
    return (
      <div data-hook={DataHook.Root} className={contentAreaClasses}>
        <div data-hook={DataHook.Content} className={contentClasses}>
          <ProductDetails hideOnHover={isDesktopAndHoverToggled} />
          {shouldShowActionButton ? (
            <ActionButton
              showOnlyOnHover={isDesktopAndHoverToggled}
              isCentered={isDesktopAndHoverToggled}
              topGutter={!isDesktopAndHoverToggled}
              onSubmit={this.handleAddToCart}
            />
          ) : null}
        </div>
      </div>
    );
  }

  private getAlignmentClass(alignment: WidgetAlignmentEnum): string {
    switch (alignment) {
      case WidgetAlignmentEnum.LEFT:
        return s.leftText;
      case WidgetAlignmentEnum.RIGHT:
        return s.rightText;
      case WidgetAlignmentEnum.CENTER:
      default:
        return s.centerText;
    }
  }

  private readonly handleAddToCart = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    const {globals} = this.props;
    return globals.handleAddToCart();
  };
}

export const ContentArea = withGlobalProps(withTranslations('globals.texts')(ContentAreaComp));
